
import { defineComponent, ref, unref, toRefs, onMounted, nextTick } from 'vue';
import { ElTable, ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { getPlans, getPromotion, IntBoolean, MAX_PER_PAGE } from '@/services/api';
import { useSavePromotionPlans } from '@/composables/api';
import { PartialPromotion } from '@/interfaces/Promotion';
import { PartialPlan } from '@/interfaces/Plan';

const DEFAULT_FORM_VALUES: PartialPromotion = {
  name: '',
  code: '',
  startedAt: '',
  endedAt: '',
  times: 0
};

export default defineComponent({
  setup() {
    const promotionId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref(DEFAULT_FORM_VALUES);
    const plans = ref([]);
    const plansTable = ref<typeof ElTable>(null);

    const { isLoading: isSaveLoading, mutate: savePlansMutate } =
      useSavePromotionPlans();

    onMounted(async() => {
      const res = await getPromotion({ promotionId });
      data.value = res.data;

      const planRes = await getPlans({ query: { perPage: MAX_PER_PAGE } });
      plans.value = planRes.data;

      await nextTick();
      plans.value.forEach(function(plan) {
        if (res.data.planIds.includes(plan.id)) {
          plansTable.value.toggleRowSelection(plan, true);
        }
      });
    });

    const multipleSelection = ref([]);
    const handleSelectionChange = (val: PartialPlan[]) => {
      multipleSelection.value = val;
    };

    const mappingPlanId = (): Array<number> => {
      return multipleSelection.value.map((item) => item.id);
    };

    const plansSave = () => {
      const planIds: Array<number> = mappingPlanId();
      savePlansMutate(
        {
          promotionId,
          data: {
            planIds
          }
        },
        {
          onSuccess() {
            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    return {
      data,
      page,
      multipleSelection,
      isSaveLoading,
      handleSelectionChange,
      plansSave,
      plans,
      plansTable
    };
  }
});
